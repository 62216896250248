@font-face {
  font-family: 'ananda-black-font';
  src: url(../public/fonts/ananda-black-font/AnandaBlackPersonalUseRegular-rg9Rx.ttf);
}

@font-face {
  font-family: 'geraldine-font';
  src: url(../public/fonts/geraldine-font/GeraldinePersonalUseItalic-PK12m.ttf);
}

@font-face {
  font-family: 'cupidon-font';
  src: url(../public/fonts/cupidon-font/Cupidon-gAeE.ttf);
}

@font-face {
  font-family: '52-pickup-font';
  src: url(../public/fonts/52-pickup-font/52Pickup-nAZR.ttf);
}

@font-face {
  font-family: 'kingsman-font';
  src: url(../public/fonts/kingsman-font/KingsmanDemo-1GVgg.ttf);
}

@font-face {
  font-family: 'feelfree-font';
  src: url(../public/fonts/feelfree-font/FeelfreePersonalUseRegular-lg2Bw.ttf);
}

body {
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.title-container {
  font-family: 'cupidon-font';
  width: 100%;
  /* height: 130px; */
  text-align: center;
  font-size: 45px;
}

.categories-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.categories-container > .category-btn {
  font-family: 'kingsman-font';
  width: 30%;
  padding: 50px;
  margin: 10px;
  font-size: 32px;
}

.question-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.question-container > .question-title-container {
  font-family: 'cupidon-font';
  font-size: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.question-container > .question-content-container {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  width: 80%;
  line-height: 80px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
